// dropdown
.dropdown-toggle {
  &:after {
    display: none;
  }
}

.border-raduis-2 {
  border-radius: 2px !important;
}
.border-raduis-4 {
  border-radius: 4px !important;
}

.inbox-transaction-list {
  .pagination {
    margin-bottom: 0;
    width: 100%;
    ul.pagination {
      justify-content: space-between;
    }
  }
}

/* custom form
======================*/
.form-label {
  &.required {
    position: relative;
    &::after {
      content: '*';
      position: absolute;
      top: 0;
      right: -12px;
      color: red;
      font-size: 20px;
    }
  }
}
.form-group {
  margin-bottom: 15px;
}

.dropdown-menu-start {
  left: -100%;
  right: auto !important;
}

.reset-options {
  padding: 0;
  margin: 0;
  list-style: none;
  &__item {
    display: flex;
    background-color: #fff;
    box-shadow: $box-shadow;
    margin-bottom: 20px;
    align-items: center;
    border-radius: 4px;
    padding: 5px;
    cursor: pointer;
  }

  &__icon {
    font-size: 22px;
    border-right: 1px solid #f2f2f3;
    padding: 5px;
    min-width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #365aa8;
  }

  &__text {
    font-size: 14px;
    margin-left: 10px;
    color: #e2e2e5;
  }
}

/* Handle Scroll style
==============================*/

.sm-scroll {
  &::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #d7d7dd;
    transition: all 0.3s ease-in-out;
    border-radius: 0.4rem;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background-color: $primary;
    cursor: pointer;
  }
}

/*ng select*/
.ng-select {
  .ng-arrow-wrapper {
    position: absolute !important;
    right: 0;
    width: 40px;
    height: 100%;
    background-color: $primary;
    .ng-arrow {
      border: none;
      position: static !important;

      &::after {
        content: '\f078';
        font-family: 'Font Awesome 6 Pro';
        font-weight: 300;
        font-size: 13px;
        color: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
/*ng select*/

.input-group-text {
  border-color: #eee;
}

.btn.btn-hide {
  &:focus {
    box-shadow: none;
    outline: none;
  }
}

.flex-grow {
  flex: 1;
}

.menu-item {
  padding-right: 15px;
  transition: background-color 0.3s ease-in-out;
  &:hover {
    // background-color: lighten($topbar-search-bg,2%);
    #page-header-user-dropdown {
      // i{
      // background-color: #284481;
      // }
      background-color: #284481;
    }
  }

  &.show {
    // background-color: lighten($topbar-search-bg,2%);
    #page-header-user-dropdown {
      // i{
      // background-color: #284481;
      // }
      background-color: #284481;
      color: #fff;
    }
  }
}

.required-action {
  padding-top: 15px;
  border-top: 1px solid #f2f2f5;
  p {
    display: flex;
    align-items: baseline;
    margin-bottom: 0;
  }
  i {
    color: $primary;
    margin-right: 10px;
  }

  .title-req {
    font-weight: 400;
  }
  .value-req {
    font-weight: 700;
  }
}

//filter transaction
.filter-transaction {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 1000;
  transform: translateY(-50%);

  &__dropdown {
    padding: 0;
  }

  &__menu {
    padding: 0;
    list-style: none;
    margin: 0;
  }

  &__item {
    cursor: pointer;
    padding: 10px;
    border-bottom: 1px solid #f6f6f6;
    &:last-child {
      border-bottom: none;
    }
  }

  &__btn {
    display: flex !important;
    align-items: center !important;

    &:focus {
      box-shadow: none;
    }
    & > i {
      color: $primary;
      margin-left: 5px;
    }
  }
}

.search-box {
  .nav-tabs {
    border-bottom: none;
  }
  .nav-link {
    border: none;
    &.active {
      border: none;
      border-bottom: 2px solid $primary;
      background-color: transparent;
    }
  }
}
//filter transaction

// user info block
.user-info-block {
  display: flex;
  span {
    white-space: nowrap;
  }
  i {
    margin-right: 5px;
    font-size: 25px;
  }
  &__dep {
    display: flex;
    flex-direction: column;
    span:nth-child(1) {
      font-size: 10px;
    }
    span:nth-child(2) {
      font-weight: 900;
    }
  }
}
// user info block

.nav-tabs .nav-link.active {
  .tab-icon {
    i {
      font-weight: 900;
    }
  }
}

body {
  &.full-page {
    @include largeScreen {
      height: 100vh;
      overflow: hidden;
    }
  }
}

.follow-icon {
  font-size: 16px !important;
}

.certification-container {
  & > div:first-child {
    padding: 15px !important;
  }
}

#sidebar-menu {
  // height: calc(100vh - 60px);
  // overflow-y: auto;
}

button.btn {
  &:focus,
  &:hover {
    box-shadow: none !important;
    outline: none !important;
    border-color: transparent;
  }
}

.inbox-transaction-result {
  .position-absolute {
    left: 0;
    position: absolute !important;
    z-index: -1;
    right: 0;
    top: 200px;
  }
}

.tox-tinymce {
  min-height: 600px !important;
}
.create_transaction_page {
  padding-top: 75px;
  position: relative;
  .position-absolute {
    left: 0;
    position: absolute !important;
    z-index: -1;
    right: 0;
    top: 200px;
  }
  .page-title-box {
    position: fixed;
    width: calc(100% - ($sidebar-width));
    left: $sidebar-width;
    right: 0;
    top: $header-height;
    padding-top: 15px;
    padding-right: 15px;
    padding-left: 15px;
    // z-index: 2;
    z-index: 105;
    background: #f2f3f3;
  }
}
